// List Group

@use "sass:math";

.list-group-flush + .card-footer {
  border-top: 0;
}

.rm-list-borders {
  .list-group-item {
    border: 0;
    padding: math.div($card-spacer-y, 1.5) 0;
  }
}

.rm-list-borders-scroll {
  .list-group-item {
    border: 0;
    padding-right: ($card-spacer-y * 1.5);
  }
}