// Variables

$spacer-lg: 3rem;
$spacer-sm: 1.5rem;

$layout-spacer-lg: 3rem;
$layout-spacer-x: 1.5rem;

$app-sidebar-width: 280px;
$app-sidebar-width-collapsed: 60px;

$logo-height: 23px;
$logo-width: 97px;

//$app-header-height: 60px;
$app-header-height: 40px;

// User
$sidebar-spacer-lg: .5rem;
$sidebar-spacer-x: .5rem;
