.table_color_cools {

  .col_sell_thru_usd {
    background-color: #77aae255 !important;
  }

  .col_sell_thru_lc {
    background-color: #9373c155 !important;
  }

  .col_sell_out_usd {
    background-color: #7ec1b555 !important;
  }

  .col_sell_out_lc {
    background-color: #92c18755 !important;
  }

  .col_sell_in_usd {
    background-color: #c1988155 !important;
  }

}