.app-sidebar.sidebar-text-dark .vertical-nav-menu {
  ul.level2 {
    margin-left: 0.2rem !important;
    padding-left: 2px !important;
  }

  ul.level2 > li > a {
    padding-left: 10px !important;
    margin-left: 10px !important;
  }

  ul.level2:before {
    left: 2px !important;
  }

  li ul li a, li ul li a i{
    color: rgba(0, 0, 0, 0.6);
  }

  li.mm-active > a i{
    font-weight: bold;
    color: rgba(0, 0, 0, 1) !important;
    opacity: 1 !important;
  }

  //li.mm-active > a.level2 i{
  //  font-weight: bold;
  //  color: rgba(0, 0, 0, 0.9) !important;
  //}

  li a.level2 {
    margin-left: 3px !important;
    padding: 0 0 0 1px !important;
    color: rgba(0, 0, 0, 0.6);

    &:before {
      left: 0 !important;
    }

    i.metismenu-level2-state-icon {
      position: relative !important;
      right: 1px !important;
      float: right !important;
      color: rgba(0, 0, 0, 0.7);
    }

    i.metismenu-level2-icon {
      left: 0 !important;
      position: relative !important;
      top: 7px !important;
      display: inline-block !important;
      color: rgba(0, 0, 0, 0.7);
    }
  }
}
