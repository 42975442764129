@import "_user-variables";


// Sidebar
.app-sidebar {
  .app-sidebar__inner {
    padding: 2px ($sidebar-spacer-lg + .3rem) $sidebar-spacer-x $sidebar-spacer-lg;
  }
}

// Navigation Menu Vertical
.vertical-nav-menu {
  ul {
    transition: padding 300ms;
    padding: 0 0 0 2rem;

    & > li > a {
      padding: 0 $sidebar-spacer-x 0;
    }
  }

  li {
    & > a.mm-active-group, & > a.mm-active {
      font-weight: bold;

      & > i.metismenu-icon {
        opacity: 1 !important;
      }
    }
  }
}

// Поле в боксі select2
li.select2-results__option {
  min-height: 2rem;
}

div.spiner-help {
  font-size: 1rem;
  height: 1rem;
  width: 1rem;
  margin-bottom: -0.1rem;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.w-1 {
  width: 1%;
}

.w-min-3r {
  min-width: 3rem;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-min-20rem {
  min-width: 20rem !important;
}

.w-min-0 {
  min-width: inherit !important;
}


.filter-block-8 {
  min-width: 8rem;
}

.filter-block-max-8 {
  max-width: 8rem;
}

.filter-block-13 {
  min-width: 13rem;
}

.filter-block-max-13 {
  max-width: 13rem;
}

.select2-responsive .select2-container {
  width: 100% !important;
}

.profile-img-container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
  margin-right: 1rem;
  //max-height: 150px;

  img {
    max-width: 150px;
    max-height: 150px;
  }
}

.hamburger {
  margin-left: -1.8rem;
  margin-top: 0.3rem;
}

.form-landing {

  .select2-selection {
    min-height: $form-floating-height;
    padding-top: 1.5rem;
    border-width: 2px !important;
  }

  label {
    z-index: 1;
    opacity: $form-floating-label-opacity;
    transform: $form-floating-label-transform;
  }
}

.row-highlight {
  //border: 1px solid blue;
  background-color: lightblue;
  transition: background-color 4s ease;
}

html {
  background: #f1f4f6;
}

.app-main .app-main__inner {
  min-height: calc(100vh - 80px);
}

.tt-striped {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

a.btn {
  border: 1px solid var(--bs-btn-active-border-color);
}

a:visited.btn, a:hover.btn, a:focus.btn, a:active.btn {
  border: 1px solid var(--bs-btn-hover-border-color);
}

@import "user-change_lg";
@import "user-change_sm";
@import "user-change_xl";