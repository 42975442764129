

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .w#{$infix}-100      {        width: 100% !important; }

    .max-wr#{$infix}-5   {    max-width:  5rem !important; }
    .max-wr#{$infix}-10  {    max-width: 10rem !important; }
    .max-wr#{$infix}-15  {    max-width: 15rem !important; }
    .max-wr#{$infix}-20  {    max-width: 20rem !important; }
    .max-wr#{$infix}-25  {    max-width: 25rem !important; }
    .max-wr#{$infix}-30  {    max-width: 30rem !important; }

    .w#{$infix}-100-0    {        width: auto !important; }

  }
}