@include media-breakpoint-up(lg) {

  .w-lg-0 {
    width: auto !important;
  }

  .w-min-lg-20rem {
    min-width: 20rem !important;
  }

  .w-min-lg-0 {
    min-width: inherit !important;
  }

}