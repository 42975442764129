// Badges

// @each $color, $value in $theme-colors {
//   .badge-#{$color} {
//     @include badge-variant($value);
//   }
// }

.badge {
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 19px;
}

.badge-light {
  background: $white;
}


.btn {
  .badge {
    margin-left: 8px;
  }
}

.btn-sm {
  .rounded-pill {
    position: absolute;
    top: -4px;
    right: -4px;
  }
}

.badge-abs {
  position: absolute;
  right: -3px;
  top: -3px;
}